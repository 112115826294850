import React from "react";
import { motion } from "framer-motion";
import "./pages.css";
import as from "./as1.png";

const Home = () => {
  return (
    <motion.div
      className="container"
      initial={{ opacity: 0 }}
      animate={{ opacity: 1 }}
      exit={{ opacity: 0 }}
    >
     <div className="header">
        <div className="logo">
          <img src={as} alt="Logo" className="home-img" />
        </div>
        <div className="title">
          <h1>My Name is Angelo Santos</h1>
        </div>
      </div>
      <div className="container-text">
        <h2>About Me</h2>
        <p>
        My name is Angelo Santos, and I live in Portugal. From a very young age, I have been fascinated by technology and programming. It intrigued me to see what we could achieve with just a box. During my school years, I had my first encounter with programming languages, starting with Pascal. Later, I delved into C, C++, and most recently, Python, followed by Java. After years of self-learning, I decided to pursue Computer Science at the University Aberta of Lisbon. In every class, I actively participated, and the more I learned, the more fascinated I became. I love it! That's why I chose this career.

Currently, I work as a System Administrator and IT Support, and I absolutely love my job. This website serves as a platform to present my CV and showcase some of the projects I've worked on.


        </p>
        <p>
        Currently, I work as a System Administrator and IT Support, and I absolutely love my job. This website serves as a platform to present my CV and showcase some of the projects I've worked on.

        </p>
        <p>
        In my free time, I enjoy playing video games, indulging in nerdy pursuits, and I'm a huge Star Wars fan. I also cherish spending time with my family.
        </p>
      </div>
      
      {/* Footer */}
      <footer className="footer">
        <div className="footer-content">
          {/* Add your footer content here */}
          <p>Connect with me:</p>
          <div className="social-icons">
            {/* Add your social media icons and links here */}
            <a href="http://linkedin.com/in/ângelo-santos-abb64a254" target="_blank" rel="noopener noreferrer">
              <i className="fab fa-linkedin"></i>
            </a>
            <a href="https://www.instagram.com/_as_blash_?igsh=dXB2MHV6cnp3dHlp&utm_source=qr" target="_blank" rel="noopener noreferrer">
              <i className="fab fa-instagram"></i>
            </a>
            <a href="mailto:web@vir-core.cloud">
              <i className="fas fa-envelope"></i>
            </a>
          </div>
        </div>
      </footer>
    </motion.div>
  );
};

export default Home;
