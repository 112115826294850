import React from "react";
import { motion } from "framer-motion";
import "./pages.css";

const Port = () => {
  return (
    <motion.div
      className="container"
      initial={{ opacity: 0 }}
      animate={{ opacity: 1 }}
      exit={{ opacity: 0 }}
    >
      <h1>Professional Profile</h1>
      <div className="container-text">
        <h2>Done works</h2>
        <p>
          SKullthorne Webpage dedicated to a wow guild.
        </p>
        <h2>Technical Skills</h2>
        <p>
        <ul>
        <li>
            <strong>System Administration:</strong> Proficient in Windows Server environments, including setup, configuration, and maintenance.
        </li>
        <li>
            <strong>Virtualization:</strong> Experienced in managing virtual machines (VMs) for efficient resource utilization and scalability.
        </li>
        <li>
            <strong>Network Administration:</strong> Knowledgeable in MikroTik routers and networking principles, with a focus on optimizing network performance and security.
        </li>
        <li>
            <strong>Programming Languages:</strong> Skilled in various programming languages, including C/C++, React, Java, and Python, enabling development of custom solutions and automation scripts.
        </li>
        <li>
            <strong>Cloud Services Administration:</strong> Capable of administering Office 365 environments, including user management, email configuration, and security settings.
        </li>
    </ul>
        </p>
      </div>
      
      {/* Footer */}
      <footer className="footer">
        <div className="footer-content">
          {/* Add your footer content here */}
          <p>Connect with me:</p>
          <div className="social-icons">
            {/* Add your social media icons and links here */}
            <a href="http://linkedin.com/in/ângelo-santos-abb64a254" target="_blank" rel="noopener noreferrer">
              <i className="fab fa-linkedin"></i>
            </a>
            <a href="https://www.instagram.com/_as_blash_?igsh=dXB2MHV6cnp3dHlp&utm_source=qr" target="_blank" rel="noopener noreferrer">
              <i className="fab fa-instagram"></i>
            </a>
            <a href="mailto:web@vir-core.cloud">
              <i className="fas fa-envelope"></i>
            </a>
          </div>
        </div>
      </footer>
    </motion.div>
  );
};

export default Port;
