import React from "react";
import { motion } from "framer-motion";
import "./pages.css";
import as from "./as1.png";

const Home = () => {
  return (
    <motion.div
      className="container"
      initial={{ opacity: 0 }}
      animate={{ opacity: 1 }}
      exit={{ opacity: 0 }}
    >
     <div className="header">
        <div className="logo">
          <img src={as} alt="Logo" className="home-img" />
        </div>
        <div className="title">
          <h1>Homepage</h1>
        </div>
      </div>
      <div className="container-text">
        <h2>Welcome to my personal website</h2>
        <p>
        Thank you for visiting my personal website, Vir-Core. Here, I showcase both my professional profile and a glimpse into my portfolio.
        </p>
        <p>
        With a commitment to you, I offer freelance IT services tailored to meet the diverse needs of businesses like yours. Whether you require technical support, network solutions, cybersecurity expertise or software consulting, I will dedicate to deliver reliable and innovative solutions.
        </p>
      </div>
      
      {/* Footer */}
      <footer className="footer">
        <div className="footer-content">
          {/* Add your footer content here */}
          <p>Connect with me:</p>
          <div className="social-icons">
            {/* Add your social media icons and links here */}
            <a href="http://linkedin.com/in/ângelo-santos-abb64a254" target="_blank" rel="noopener noreferrer">
              <i className="fab fa-linkedin"></i>
            </a>
            <a href="https://www.instagram.com/_as_blash_?igsh=dXB2MHV6cnp3dHlp&utm_source=qr" target="_blank" rel="noopener noreferrer">
              <i className="fab fa-instagram"></i>
            </a>
            <a href="mailto:web@vir-core.cloud">
              <i className="fas fa-envelope"></i>
            </a>
          </div>
        </div>
      </footer>
    </motion.div>
  );
};

export default Home;
