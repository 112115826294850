import React from "react";
import { BrowserRouter as Router,  Route, Routes, useLocation } from "react-router-dom";
import Home from "./Pages/Home";
import About from "./Pages/About";
import Port from "./Pages/port";

import "./Pages/pages.css";
import {AnimatePresence} from "framer-motion";

function AnimatedRoutes() {
    const location = useLocation();

    return (
    <AnimatePresence>
        <Routes location={location} key={location.pathname}>
            <Route path="/" element={<Home />} />
            <Route path="/about" element={<About />} />
            <Route path="/port" element={<Port />} />
            
        </Routes>
    </AnimatePresence> 
    );


}

export default AnimatedRoutes;