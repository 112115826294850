import "./App.css";
import NavBar from "./components/NavBar";
import { BrowserRouter as Router,  Route, Routes, useLocation } from "react-router-dom";
import AnimatedRoutes from "./components/animatedRoutes";



function App() {
 
  return (
    <div className="pages">
      <Router>
        <NavBar />
        <AnimatedRoutes></AnimatedRoutes>
      </Router>
      
    </div>
  );
}

export default App;
