import React, { useState } from "react";
import { NavLink } from "react-router-dom";
import "./NavBar.css";



function NavBar() {
  const [showMenu, setShowMenu] = useState(false);

  const handleMouseEnter = () => {
    setShowMenu(true);
  };

  const handleMouseLeave = () => {
    setShowMenu(false);
  };

  return (
    <nav className="navbar">
      <div className="nav-container">
        <NavLink exact to="/" className="nav-logo">
          <span>
           Vir-Core
          </span>
        </NavLink>

        <div
          className="nav-icon"
          onMouseEnter={handleMouseEnter}
         
        >
          <span className="menu-title">
            {showMenu ? "Select Options" : "Menu" }
          </span>
        </div>

        <ul className={`nav-menu ${showMenu ? "active" : ""}`} onMouseLeave={handleMouseLeave}>
          <li className="nav-item">
            <NavLink exact to="/" activeClassName="active" className="nav-links">
              Home
            </NavLink>
          </li>
          <li className="nav-item">
            <NavLink exact to="/About" activeClassName="active" className="nav-links">
              About
            </NavLink>
          </li>
          <li className="nav-item">
            <NavLink exact to="/port" activeClassName="active" className="nav-links">
            Professional Profile
            </NavLink>
          </li>
          
          
        </ul>
      </div>

    </nav>
    
  );
}

export default NavBar;
